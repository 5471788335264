import { Component, OnInit } from '@angular/core';
import { Router, Scroll, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'gestion';

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        const mainDiv = document.getElementById('scrollBody');
        mainDiv.scrollTop = 0;
    });
  }

}


