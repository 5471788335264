
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDW_zmsvBijmW0ofJsI4eoTfKhuXGxu1D4",
    authDomain: "estetical-dev.firebaseapp.com",
    databaseURL: "https://estetical-dev.firebaseio.com",
    projectId: "estetical-dev",
    storageBucket: "estetical-dev.appspot.com",
    messagingSenderId: "110482676324",
    appId: "1:110482676324:web:60db3260be4b6f269e9d71",
    measurementId: "G-HLXRX6LFDE"
  },
  stripe_key: 'pk_test_NzhH55v85HRht3Ms4DhZhd8W00uiWkBHUH'
};






// apiKey: "AIzaSyDW_zmsvBijmW0ofJsI4eoTfKhuXGxu1D4",
// authDomain: "estetical-dev.firebaseapp.com",
// databaseURL: "https://estetical-dev.firebaseio.com",
// projectId: "estetical-dev",
// storageBucket: "estetical-dev.appspot.com",
// messagingSenderId: "110482676324",
// appId: "1:110482676324:web:60db3260be4b6f269e9d71",
// measurementId: "G-HLXRX6LFDE"
