import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';

import { AuthGuardGuard } from './guards/auth-guard.guard';



const routes: Routes = [

  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full' 
  // }, 
  {
    path: '',
    loadChildren: () => import('./pagesV2/home-page/home-page.module').then(m => m.HomePageModule)
  },

  {
    path: 'admin',
    loadChildren: () => import('./pagesV2/admin-pages/admin-page/admin-page.module').then(m => m.AdminPageModule),
    canActivate: [AuthGuardGuard],
    data: {
      role: ['worker', 'admin', 'superAd']
    }
  },
  {
    path: 'super',
    loadChildren: () => import('./pagesV2/super-pages/super-nav-page/super-nav-page.module').then(m => m.SuperNavPageModule),
    canActivate: [AuthGuardGuard],
    data: {
      role: ['superAd']
    }
  },

  {
    path: 'centro/:id',
    loadChildren: () => import('./pagesV2/center-tem-page/center-tem-page.module').then(m => m.CenterTemPageModule)
  },


  {
    path: 'cookies',
    loadChildren: () => import('./pagesV2/footer-pages/cookies-page/cookies-page.module').then(m => m.CookiesPageModule)
  },

  {
    path: 'terminos-condiciones',
    loadChildren: () => import('./pagesV2/footer-pages/terms-page/terms-page.module').then(m => m.TermsPageModule)
  },

  {
    path: 'condiciones-venta',
    loadChildren: () => import('./pagesV2/footer-pages/sells-terms-page/sells-terms-page.module').then(m => m.SellsTermsPageModule)
  },

  {
    path: 'condiciones-venta',
    loadChildren: () => import('./pagesV2/footer-pages/sells-terms-page/sells-terms-page.module').then(m => m.SellsTermsPageModule)
  },

  {
    path: 'suscripcion',
    loadChildren: () => import('./pagesV2/subs-page/subs-page.module').then(m => m.SubsPageModule)
  },

  {
    path: 'gracias',
    loadChildren: () => import('./pagesV2/thanks-page/thanks-page.module').then(m => m.ThanksPageModule)
  },

  
];
//  { scrollPositionRestoration: 'top' }
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }), ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
