import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { take, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthService, private toastr: ToastrService) {

  }
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const expectedRole = route.data.role;
    return this.auth.user.pipe(
      take(1),
      map(user => {
        if (!user) {
          this.router.navigateByUrl('/inicio');
          return false;
        } else {
          const role = user.role;
          const found = expectedRole.some( r => role.indexOf(r) >= 0);
          if (found === true) {
            return true;
          } else {
            if (user.role.includes('worker') || user.role.includes('superAd')) {
              this.toastr.warning('Tu rol no te permite entrar', 'Error', {
                timeOut: 4000
              });
              return false;
            } else {
              this.router.navigateByUrl('/inicio');
              return false;
            }
          }
        }
      })
    );
  }
}
 