import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import {AngularFireAnalyticsModule, ScreenTrackingService} from '@angular/fire/analytics'
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  FUNCTIONS_REGION
} from '@angular/fire/functions';
import { ScheduleAllModule} from '@syncfusion/ej2-angular-schedule';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { ToastrModule } from 'ngx-toastr';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { HttpErrorInterceptor } from './services/http-error-interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    FontAwesomeModule,
    ScheduleAllModule,
    HttpClientModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastrModule.forRoot(),
    
  ],
  // tslint:disable-next-line: max-line-length
  providers: [{ provide: FUNCTIONS_REGION, useValue: 'europe-west1' }, MDBSpinningPreloader, ScreenTrackingService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
 }
