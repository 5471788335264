import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { take, switchMap, tap, map } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import * as firebase from 'firebase/app';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
user: Observable<any>;
currentUser = new BehaviorSubject(null);

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private functions: AngularFireFunctions) { 
  this.user = this.afAuth.authState.pipe(
    switchMap(user => {
      if (user) {
        return this.db.doc(`users/${user.uid}`).valueChanges().pipe(
          take(1),
         );
      } else {
        this.currentUser.next(null);
        return of(null);
      }
    }));
  }
  sendVerify() {
    this.afAuth.auth.currentUser.sendEmailVerification();
  }

  signIn(credentials): Observable<any> {
    try {
      return from(this.afAuth.auth.signInWithEmailAndPassword(credentials.mail, credentials.password)).pipe(
        switchMap(user => {
          if (user) {
          return this.db.doc(`users/${user.user.uid}`).valueChanges().pipe(
            take(1));
          } else {
            return of(null);
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  forgotPassword(value) {
    this.afAuth.auth.sendPasswordResetEmail(value);
  }

  newPasswordAdmin(password: string, uid: string) {
    this.db.collection('users').doc(uid).update({
      password: password
    });
  }

}
